<template>
  <el-dialog
    :title="type==='create'?'新增':'编辑'"
    :visible.sync="visible"
    custom-class="createDialog dataM"
    width="600px"
    :close-on-click-modal="false"
    @close="handleClose"
    >
    <el-form :model="form" :rules="rules" ref="ruleForm" class="dialogContent" :show-message="false">
      <div class="left">
        <el-form-item prop="staffName" class="item" label="姓名">
          <el-input v-model.trim="form.staffName" clearable/>
        </el-form-item>
        <el-form-item prop="staffDepartment" class="item" label="部门">
          <el-input v-model.trim="form.staffDepartment" clearable/>
        </el-form-item>
        <el-form-item prop="workshiftSchemeName" class="item" label="日历方案">
          <el-select v-model="form.workshiftSchemeName" clearable>
            <el-option v-for="(v,i) in maintenancePlanList" :key="i" :value="v.workshiftSchemeName">
              {{v.workshiftSchemeName}}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="staffStatus" class="item" label="状态">
          <el-select v-model="form.staffStatus">
            <el-option :value="1" label="在职"/>
            <el-option :value="2" label="离职"/>
          </el-select>
        </el-form-item>
      </div>
      <div class="right">
        <el-form-item prop="staffNo" class="item" label="员工编号">
          <el-input v-model.trim="form.staffNo" clearable/>
        </el-form-item>
        <el-form-item prop="jobTitle" class="item" label="岗位">
          <el-input v-model.trim="form.jobTitle" clearable/>
        </el-form-item>
        <el-form-item prop="skill" class="item" label="技能">
          <el-input v-model.trim="form.skill" clearable/>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取消</el-button>
      <el-button type="primary" @click="handleSure()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { 
  addStaff,
  editStaff,
  getWorkshift
  } from '@/api/data.js'
export default {
  name:'createStaff',
  props:{
    currentData:{
      require:false,
      default:()=>{}
    },
    createVisible:{
      require:true,
      default:false
    },
    type:{
      default:'edit'
    }
  },
  data(){
    return{
      that:this,
      form:{
        staffName:'',
        staffStatus:'',
        staffNo:'',
        staffDepartment:'',
        jobTitle:'',
        skill:'',
        workshiftSchemeName:'',
      },
      rules:{
        staffNo:[
          { required: true, message: '请输入员工编号', trigger: 'change' },
        ],
        staffName:[
          {required: true, message: '请输入姓名', trigger: 'change'}
        ],
        staffDepartment:[
          {required: true, message: '请输入部门', trigger: 'change'}
        ],
        jobTitle:[
          {required: true, message: '请输入岗位', trigger: 'change'}
        ],
        staffStatus:[
          {required: true, message: '请输入状态', trigger: 'change'}
        ],
      },
      maintenancePlanList:[],
    }
  },
  computed:{
    visible:{
      get(){
        return this.createVisible
      },
      set(){}
    },
  },
  watch:{
    createVisible:{
      handler(val){
        if(val){
          this.form = {...this.currentData}
          this.getWorkList()
        }
      },
      immediate:true
    },
    // currentData(){
    //   this.form = {
    //   ...this.currentData
    //   }
    // }
  },
  methods:{
    getWorkList(){
      let params = {
        page:1,
        size:999
      }
      getWorkshift(params).then(res=>{
        if(res.code === 0){
          this.maintenancePlanList = res.data.records
        }else{
          this.$message({
            message:res.msg || '接口错误',
            showClose: true,
            duration: 0,
            type:'error'
          })
        }
      }).catch(()=>{
        this.$message({
          message:'接口错误',
          showClose: true,
          duration: 0,
          type:'error'
        })
      })
    },
    handleClose(){
      this.$emit('update:createVisible',false)
    },
    handleSure(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.handleSubmit()
        } else {
          this.$message({
            message:'请补充完整信息',
            showClose: true,
            duration: 0,
            type:'error'
          })
          return false;
        }
      });
    },
    handleSubmit(){
      let params = {
        ...this.form
      }
      let Api = this.type==='create'?addStaff:editStaff
      Api(params).then(res=>{
        if(res.code == 0){
          this.$emit('update:createVisible',false)
          this.$emit('handleSearch')
          this.$message.success('操作成功')
        }else{
          this.$message({
            message:res.msg || '接口错误',
            showClose: true,
            duration: 0,
            type:'error'
          })
        }
      })
    },
    //添加保养项目
    addItem(){
      this.form.maintenanceItems.push({value:""})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
<style lang="scss">
.createDialog{
  .el-form-item{
    margin-bottom:10px!important;
  }
  .el-form-item__label{
    font-size: 14px;
    line-height: 28px!important;
  }
  .el-select,.el-input{
    width: 100%!important;
  }
  .el-form-item.is-error .el-input__inner{
    border-color: #F56C6C!important;
  }
  .dialogContent{
    display:flex;
    .right,.left{
      display: flex;
      flex-direction: column;
      width:48%;
      .item{
        .item2{
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>